<template>
    <section v-loading.fullscreen.lock="m__loading" class="relative flex">
        <div
            v-loading.fullscreen.lock="m__fetching_ads_accounts"
            class="w-[240px] min-w-[240px] flex flex-col space-y-4 left-0 h-fit"
        >
            <div v-if="old_campaigns.length > 1" class="component-box">
                <div
                    class="flex flex-col space-y-2 max-h-[240px] overflow-auto"
                >
                    <div
                        v-for="(item, index) in old_campaigns"
                        :key="item.infor_campaign.campaign_id"
                        class="flex items-center space-x-2 hover:bg-border group rounded-lg cursor-pointer relative"
                        @click="selected_old_campaign = item"
                    >
                        <div class="absolute -bottom-1 left-[52px]">
                            <div
                                class="flex space-x-[6px] text-xxs text-brand-atosa font-semibold"
                            >
                                <p>{{ $t('common.adgroup') }}</p>
                                <p v-if="item.step === 2">
                                    {{ $t('common.ad') }}
                                </p>
                            </div>
                        </div>
                        <div
                            class="min-w-[44px] w-11 h-11 util-flex-center rounded-lg text-white !ml-0"
                            :class="[
                                selected_old_campaign.infor_campaign
                                    .campaign_id ===
                                item.infor_campaign.campaign_id
                                    ? 'bg-brand-atosa--light'
                                    : 'bg-border group-hover:bg-brand-atosa--light'
                            ]"
                        >
                            <p class="text-sm">
                                {{ index + 1 }}
                            </p>
                        </div>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.infor_campaign.campaign_name"
                            placement="right"
                        >
                            <p
                                class="truncate w-[140px] text-xs"
                                :class="[
                                    selected_old_campaign.infor_campaign
                                        .campaign_id ===
                                    item.infor_campaign.campaign_id
                                        ? 'font-semibold'
                                        : ''
                                ]"
                            >
                                {{ item.infor_campaign.campaign_name }}
                            </p>
                        </el-tooltip>
                    </div>
                </div>
            </div>

            <ProcessBar
                class="w-full"
                :step.sync="step"
                :created_campaign_id="created_campaign_id"
            ></ProcessBar>

            <el-radio-group v-model="create_with_multi_ads_account">
                <el-radio :label="0">Một tài khoản</el-radio>
                <el-radio :label="1">Nhiều tài khoản</el-radio>
            </el-radio-group>

            <template v-if="create_with_multi_ads_account">
                <label-outside-input-select
                    :label="$t('common.business_center')"
                    bg="bg-transparent"
                >
                    <el-select
                        v-model="m__selected_business_center"
                        value-key="bc_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_business_center')
                        "
                        class="el-select-border-none w-full"
                        @change="
                            m__fetchAdsAccountsByBusinessCenter(
                                m__selected_business_center.bc_id
                            )
                        "
                    >
                        <el-option-group :label="$t('common.business_center')">
                            <el-option
                                v-for="item in m__business_centers"
                                :key="item.bc_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="flex items-center py-1 space-x-2">
                                    <div class="flex flex-col">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <span class="component-text-desc">
                                            #{{ item.bc_id }}
                                        </span>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>

                <label-outside-input-select
                    :label="$t('common.advertiser_account')"
                    bg="bg-transparent"
                >
                    <el-select
                        :value="m__selected_ads_account_list"
                        value-key="advertiser_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_ads_account')
                        "
                        class="el-select-border-none w-full"
                        @change="m__handleChangeAdsAccountList"
                        multiple
                    >
                        <el-option-group
                            :label="$t('common.advertiser_account')"
                        >
                            <el-option
                                v-for="item in m__ads_accounts_by_bc"
                                :key="item.advertiser_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="relative">
                                    <div class="flex flex-col py-1">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <div
                                            class="flex items-center space-x-1 leading-3"
                                        >
                                            <i class="el-icon-money"></i>

                                            <span class="text-xs">
                                                {{
                                                    p__renderMoneyByCurrency(
                                                        item.balance,
                                                        item.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="flex items-center">
                                            <span class="component-text-desc">
                                                #{{ item.advertiser_id }}
                                            </span>

                                            <div
                                                v-if="item.belong_to_atosa"
                                                class="ml-auto bg-brand-atosa--light util-flex-center px-1 text-white h-5"
                                            >
                                                <span
                                                    class="text-xxxs uppercase"
                                                >
                                                    atosa
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>
            </template>
            <template v-else>
                <label-outside-input-select
                    :label="$t('common.advertiser_account')"
                    bg="bg-transparent"
                >
                    <el-select
                        :value="m__selected_ads_account"
                        value-key="advertiser_id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_ads_account')
                        "
                        class="el-select-border-none w-full"
                        @change="handleChangeAdsAccount"
                    >
                        <el-option-group
                            :label="$t('common.advertiser_account')"
                        >
                            <el-option
                                v-for="item in m__ads_accounts"
                                :key="item.advertiser_id"
                                :label="item.name"
                                :value="item"
                            >
                                <div class="relative">
                                    <div class="flex flex-col py-1">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{ item.name }}
                                        </span>
                                        <div
                                            class="flex items-center space-x-1 leading-3"
                                        >
                                            <i class="el-icon-money"></i>

                                            <span class="text-xs">
                                                {{
                                                    p__renderMoneyByCurrency(
                                                        item.balance,
                                                        item.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div class="flex items-center">
                                            <span class="component-text-desc">
                                                #{{ item.advertiser_id }}
                                            </span>

                                            <div
                                                v-if="item.belong_to_atosa"
                                                class="ml-auto bg-brand-atosa--light util-flex-center px-1 text-white h-5"
                                            >
                                                <span
                                                    class="text-xxxs uppercase"
                                                >
                                                    atosa
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </label-outside-input-select>
            </template>

            <div class="component-box">
                <p class="text-xs">{{ $t('common.ad_account_balance') }} sd</p>
                <p class="text-sm font-semibold">
                    {{ current_balance | f__format_currency_vnd }}
                </p>

                <el-button
                    v-if="belong_to_atosa"
                    type="success"
                    plain
                    size="small"
                    icon="el-icon-money"
                    class="mx-auto mt-2"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${p__route.payment}?advertiser_id=${advertiser_id}`
                        })
                    "
                >
                    {{ $t('page.payment.add_balance') }}
                </el-button>
            </div>

            <el-button
                v-if="created_campaign_id"
                icon="el-icon-refresh-right"
                @click="handleCreateAgain"
            >
                {{ $t('button.create_again') }}
            </el-button>

            <el-alert
                :title="$t('common.desc_create_campaign_by_atosa')"
                type="warning"
                class="mb-2"
                :closable="false"
            >
            </el-alert>
        </div>

        <div
            v-if="bc_id && advertiser_id"
            class="ml-[20px] w-full max-w-[1000px]"
        >
            <el-alert
                v-if="step === 1"
                type="success"
                class="mb-2"
                :closable="false"
            >
                <p slot="title">
                    {{ $t('page.campaign.contact_atosa') }}.
                    <a
                        target="_blank"
                        class="underline"
                        :href="VUE_APP_ATOSA_CONTACT_FORM"
                    >
                        {{ $t('common.click_here') }}
                    </a>
                </p>
            </el-alert>

            <div v-if="step === 1" class="component-box mb-4">
                <el-tabs v-model="active_tab">
                    <el-tab-pane
                        :label="$t('page.campaign.create_new')"
                        name="create_new"
                    ></el-tab-pane>
                    <el-tab-pane
                        :label="$t('page.campaign.use_existing')"
                        name="use_existing"
                    >
                        <p class="component-label mt-6">
                            {{ $t('common.campaign') }}
                        </p>
                        <el-select
                            v-model="selected_existing_campaign"
                            v-el-select-loadmore="loadMore"
                            value-key="campaign_id"
                            size="small"
                            filterable
                            :placeholder="$t('input.placeholder.please_select')"
                            class="w-1/2"
                        >
                            <el-option-group :label="$t('common.campaign')">
                                <el-option
                                    v-for="item in m__data_for_pagination"
                                    :key="item.campaign_id"
                                    :value="item"
                                    :label="`${item.campaign_name} (${item.objective_type})`"
                                >
                                    <div class="flex flex-col py-2">
                                        <span
                                            class="leading-3 text-xs font-semibold"
                                        >
                                            {{
                                                `${item.campaign_name} (${item.objective_type})`
                                            }}
                                        </span>
                                        <span class="component-text-desc">
                                            #{{ item.campaign_id }}
                                        </span>
                                    </div>
                                </el-option>
                            </el-option-group>
                        </el-select>

                        <div class="flex mt-10">
                            <el-button
                                type="primary"
                                size="small"
                                class="ml-auto"
                                icon="el-icon-right"
                                :disabled="!selected_existing_campaign"
                                @click="handleNextStepByExistingCampaign"
                            >
                                {{ $t('button.continue') }}
                            </el-button>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>

            <StepOneForCampaign
                v-if="step === 1 && active_tab === 'create_new'"
                :step.sync="step"
                :checked_created_show_budget_adgroup.sync="
                    checked_created_show_budget_adgroup
                "
                :currency="currency"
                :min_budget_campaign="min_budget_campaign"
                :objective_type.sync="objective_type"
                :infor_campaign.sync="infor_campaign"
                :default_state_infor_campaign="default_state_infor_campaign"
                :belong_to_atosa="belong_to_atosa"
                :ads_account="m__selected_ads_account"
                :tiktok_business="m__selected_tiktok_business"
            ></StepOneForCampaign>

            <StepTwoForAdgroup
                v-if="step === 2"
                :step.sync="step"
                :checked_created_show_budget_adgroup="
                    checked_created_show_budget_adgroup
                "
                :infor_adgroups.sync="infor_adgroups"
                :infor_campaign="infor_campaign"
                :currency="currency"
                :timezone="timezone"
                :created_campaign_id="created_campaign_id"
                :min_budget_adgroup="min_budget_adgroup"
                :advertiser_id="advertiser_id"
                :tiktok_account_id="tiktok_account_id"
                :bc_id="bc_id"
                :belong_to_atosa="belong_to_atosa"
                :objective_type="
                    active_tab === 'create_new'
                        ? infor_campaign.objective_type
                        : selected_existing_campaign.objective_type
                "
            ></StepTwoForAdgroup>

            <StepThreeForAd
                v-if="step === 3"
                :step.sync="step"
                :infor_campaign="
                    active_tab === 'create_new'
                        ? infor_campaign
                        : selected_existing_campaign
                "
                :infor_adgroups="infor_adgroups"
                :advertiser_id="advertiser_id"
                :advertiser_id_list="advertiser_id_list"
                :create_with_multi_ads_account="create_with_multi_ads_account"
                :currency="currency"
                :belong_to_atosa="belong_to_atosa"
                :created_campaign_id.sync="created_campaign_id"
                :tiktok_account_id="tiktok_account_id"
                :current_balance="current_balance"
                :bc_id="bc_id"
                :has_campaign="has_campaign"
                :active_tab="active_tab"
                :objective_type="
                    active_tab === 'create_new'
                        ? infor_campaign.objective_type
                        : selected_existing_campaign.objective_type
                "
            ></StepThreeForAd>

            <StepTwoPoinOneForAdgroup
                v-if="step === 2.1"
                :step.sync="step"
                :infor_adgroups.sync="infor_adgroups"
                :infor_campaign="
                    active_tab === 'create_new'
                        ? infor_campaign
                        : selected_existing_campaign
                "
                :currency="currency"
                :timezone="timezone"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
                :min_budget_adgroup="min_budget_adgroup"
                :belong_to_atosa="belong_to_atosa"
                :bc_id="bc_id"
                :objective_type="
                    active_tab === 'create_new'
                        ? infor_campaign.objective_type
                        : selected_existing_campaign.objective_type
                "
            ></StepTwoPoinOneForAdgroup>
        </div>
    </section>
</template>

<script>
import StepOneForCampaign from './step_1_campaign'
import StepTwoForAdgroup from './step_2_adgroup'
import StepTwoPoinOneForAdgroup from './step_2_1_faster'
import StepThreeForAd from './step_3_ad'
import ProcessBar from './process-bar'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { getAdvertiserAccountDetail } from '@/services/atosa-tiktok-ads/advertiser-account'
import { getCampaigns } from '@/services/atosa-tiktok-ads/campaign'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import stickyTopMixin from '@/plugins/mixins/sticky-top'
import moment from 'moment'
import { getAdGroups } from '@/services/atosa-tiktok-ads/adgroup'
import selectedBusinessCenterMixin from '@/plugins/mixins/selected-business-center'

const default_state_infor_campaign = {
    campaign_name: '',
    budget: 0,
    budget_mode: 'BUDGET_MODE_INFINITE',
    objective_type: 'PRODUCT_SALES',
    campaign_type: '',
    app_promotion_type: '',
    switch_split_test: false,
    switch_camp_budget_optimization: false,
    switch_set_camp_budget: false
}

export default {
    components: {
        ProcessBar,
        StepOneForCampaign,
        StepTwoForAdgroup,
        StepTwoPoinOneForAdgroup,
        StepThreeForAd
    },

    mixins: [
        selectedTiktokBusinessMixin,
        paginationDataMixin,
        stickyTopMixin,
        selectedBusinessCenterMixin
    ],

    data() {
        return {
            checked_created_show_budget_adgroup: false,
            min_budget_campaign: 0,
            min_budget_adgroup: 0,
            old_campaigns: [],
            selected_old_campaign: null,
            fetch_bc_is_loading: false,
            active_tab: 'create_new',
            selected_existing_campaign: null,
            ads_accounts: [],
            step: 0,
            currency: '',
            timezone: 'Asia/Ho_Chi_Minh',
            default_state_infor_campaign,
            objective_type: '',
            created_campaign_id: '',
            has_campaign: false,
            infor_campaign: default_state_infor_campaign,
            infor_adgroups: [],
            VUE_APP_ATOSA_CONTACT_FORM: process.env.VUE_APP_ATOSA_CONTACT_FORM,
            m__selected_business_center: null,
            create_with_multi_ads_account: 0,
            advertiser_id_list: []
        }
    },

    computed: {
        current_balance() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.balance
            }

            return 0
        },

        advertiser_id() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.advertiser_id
            }

            return ''
        },

        recommend_budget_adgroup() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.recommend_budget_adgroup
            }

            return 0
        },

        belong_to_atosa() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.belong_to_atosa
            }

            return ''
        },

        tiktok_account_id() {
            if (this.m__selected_tiktok_business) {
                return this.m__selected_tiktok_business.id
            }
            return ''
        },

        bc_id() {
            // console.log('====================================')
            // console.log(this.m__selected_business_center, 'ldksafjladsfkjdlsaf')
            // console.log('====================================')

            if (this.create_with_multi_ads_account) {
                if (this.m__selected_business_center) {
                    return this.m__selected_business_center.bc_id
                }
            } else {
                if (this.m__selected_ads_account) {
                    return this.m__selected_ads_account.bc_id
                }
            }

            return ''
        }
    },

    watch: {
        m__selected_ads_account_list() {
            console.log(this.m__selected_ads_account_list, 'data mới là')
            this.advertiser_id_list = this.m__selected_ads_account_list.map(
                (i) => i.advertiser_id
            )
        },
        bc_id() {
            if (this.bc_id) {
                this.infor_campaign = default_state_infor_campaign
                this.infor_adgroups = []

                this.handleChangeObjectiveType()
            }
        },

        belong_to_atosa() {
            this.objective_type = this.belong_to_atosa
                ? 'PRODUCT_SALES'
                : 'REACH'
        },

        tiktok_account_id() {
            this.fetchAdsAccountsByTiktokBusiness()
        },

        advertiser_id() {
            this.handleRedirectToStep()

            this.fetchDetailAdsAccount()

            this.m__data_for_pagination = []
            this.selected_existing_campaign = null
            this.fetchCampaigns()
        },

        objective_type() {
            this.handleChangeObjectiveType()
            this.changeBudgetByCurrency()
        },

        selected_existing_campaign() {
            this.created_campaign_id =
                this.active_tab === 'create_new'
                    ? ''
                    : this.selected_existing_campaign.campaign_id
        },

        active_tab() {
            if (this.active_tab === 'create_new') {
                this.created_campaign_id = ''
            }
        },

        step(newState, oldState) {
            const objective_type =
                this.active_tab === 'create_new'
                    ? this.infor_campaign.objective_type
                    : this.selected_existing_campaign.objective_type

            if (oldState === 2) {
                const now = moment()
                const date = now.format('YYYYMMDDHHmmss')

                this.infor_adgroups = this.infor_adgroups.map((adgroup) => {
                    if (adgroup.extension.must_change_ad) {
                        const ad = JSON.parse(
                            JSON.stringify({
                                ...this.p__default_state_infor_ad,
                                ad_name: `${this.$t('common.ad')}_${date}`,
                                extension: {
                                    ...this.p__default_state_infor_ad.extension,
                                    id: this.p__renderId()
                                }
                            })
                        )

                        this.changeInforAdByObjectiveType(
                            ad,
                            adgroup,
                            objective_type
                        )

                        adgroup.extension.infor_ads = [ad]
                        adgroup.extension.must_change_ad = false
                        adgroup.extension.reset_budget = false
                    }

                    return adgroup
                })
            }

            const view_layout = document.getElementById('view_layout')
            view_layout.scrollTop = 0
        }
    },

    mounted() {
        this.fetchAdsAccountsByTiktokBusiness()

        // this.fetchAdsAccountsByBusinessCenter()

        this.handleChangeObjectiveType()

        this.p__event_bus.$on(
            'eb_create_campaign_go_to_step2_after_created_success',
            this.handleGoToStep2AfterCreatedSuccess
        )
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_create_campaign_go_to_step2_after_created_success',
            this.handleGoToStep2AfterCreatedSuccess
        )
    },

    methods: {
        handleChangeObjectiveType() {
            const now = moment()

            const date = now.format('YYYYMMDDHHmmss')

            const objective_type =
                this.active_tab === 'create_new'
                    ? this.objective_type
                    : this.selected_existing_campaign.objective_type

            let schedule_start_time = ''
            let schedule_end_time = ''
            if (objective_type === 'PRODUCT_SALES') {
                schedule_start_time = now
                    .add(10, 'minutes')
                    .format(this.p__format_schedule_tiktok_time)
                schedule_end_time = now
                    .add(2, 'hours')
                    .format(this.p__format_schedule_tiktok_time)
            } else {
                schedule_start_time = now
                    .add(1, 'days')
                    .format(this.p__format_schedule_tiktok_time)
                schedule_end_time = now
                    .add(31, 'days')
                    .format(this.p__format_schedule_tiktok_time)
            }

            this.infor_adgroups = [
                {
                    ...this.p__default_state_infor_adgroup,
                    extension: {
                        must_change_ad: true,
                        reset_budget: true,
                        ...this.p__default_state_infor_adgroup.extension,
                        id: this.p__renderId()
                    },
                    adgroup_name: `${this.$t('common.adgroup')}_${date}`,
                    budget: this.recommend_budget_adgroup,
                    pacing: 'PACING_MODE_SMOOTH',
                    optimization_goal: 'CLICK',
                    optimization_event: '',
                    promotion_type: 'WEBSITE',
                    billing_event: '',
                    store_authorized_bc_id: '',
                    schedule_start_time,
                    schedule_end_time
                }
            ]

            this.changeInforAdgroupByObjectiveType(objective_type)
        },

        async handleRedirectToStep() {
            if (this.$route.query.campaign_id) {
                this.has_campaign = true
                const campaign_ids = this.$route.query.campaign_id.split(',')
                this.m__loading = true

                try {
                    const response = await getCampaigns(
                        this.tiktok_account_id,
                        {
                            advertiser_id: this.advertiser_id,
                            filtering: JSON.stringify({
                                campaign_ids
                            })
                        }
                    )
                    if (response.data.list.length > 0) {
                        this.old_campaigns = response.data.list.map(
                            (campaign) => ({
                                step: 1,
                                infor_campaign: campaign,
                                infor_adgroups: [
                                    {
                                        ...this.p__default_state_infor_adgroup,
                                        extension: {
                                            ...this
                                                .p__default_state_infor_adgroup
                                                .extension,
                                            id: this.p__renderId()
                                        },
                                        adgroup_name: `${this.$t(
                                            'common.adgroup'
                                        )}_${date}`,
                                        budget: this.recommend_budget_adgroup,
                                        pacing: 'PACING_MODE_SMOOTH',
                                        optimization_goal: 'CLICK',
                                        optimization_event: '',
                                        promotion_type: 'WEBSITE',
                                        billing_event: '',
                                        store_authorized_bc_id: '',
                                        schedule_start_time,
                                        schedule_end_time
                                    }
                                ]
                            })
                        )

                        this.selected_old_campaign = this.old_campaigns[0]

                        this.infor_campaign = response.data.list[0]
                        this.created_campaign_id =
                            response.data.list[0].campaign_id

                        const now = moment()
                        const date = now.format('YYYYMMDDHHmmss')

                        let schedule_start_time = ''
                        let schedule_end_time = ''

                        if (
                            this.infor_campaign.objective_type ===
                            'PRODUCT_SALES'
                        ) {
                            schedule_start_time = now
                                .add(10, 'minutes')
                                .format(this.p__format_schedule_tiktok_time)
                            schedule_end_time = now
                                .add(2, 'hours')
                                .format(this.p__format_schedule_tiktok_time)
                        } else {
                            schedule_start_time = now
                                .add(1, 'days')
                                .format(this.p__format_schedule_tiktok_time)
                            schedule_end_time = now
                                .add(31, 'days')
                                .format(this.p__format_schedule_tiktok_time)
                        }

                        this.infor_adgroups = [
                            {
                                ...this.p__default_state_infor_adgroup,
                                extension: {
                                    ...this.p__default_state_infor_adgroup
                                        .extension,
                                    id: this.p__renderId()
                                },
                                adgroup_name: `${this.$t(
                                    'common.adgroup'
                                )}_${date}`,
                                budget: this.recommend_budget_adgroup,
                                pacing: 'PACING_MODE_SMOOTH',
                                optimization_goal: 'CLICK',
                                optimization_event: '',
                                promotion_type: 'WEBSITE',
                                billing_event: '',
                                store_authorized_bc_id: '',
                                schedule_start_time,
                                schedule_end_time
                            }
                        ]
                        this.changeInforAdgroupByObjectiveType(
                            this.infor_campaign.objective_type
                        )

                        this.step = 2
                    }
                } catch (error) {
                    console.error(error)
                }

                this.m__loading = false

                return
            }

            if (this.$route.query.adgroup_id) {
                this.m__loading = true

                try {
                    const response_detail_adgroup = await getAdGroups(
                        this.tiktok_account_id,
                        {
                            advertiser_id: this.advertiser_id,
                            filtering: JSON.stringify({
                                adgroup_ids: [this.$route.query.adgroup_id]
                            })
                        }
                    )

                    if (response_detail_adgroup.data.list.length === 1) {
                        const response_detail_campaign = await getCampaigns(
                            this.tiktok_account_id,
                            {
                                advertiser_id: this.advertiser_id,
                                filtering: JSON.stringify({
                                    campaign_ids: [
                                        response_detail_adgroup.data.list[0]
                                            .campaign_id
                                    ]
                                })
                            }
                        )

                        if (response_detail_campaign.data.list.length === 1) {
                            this.infor_campaign =
                                response_detail_campaign.data.list[0]
                            this.created_campaign_id =
                                response_detail_campaign.data.list[0].campaign_id

                            const now = moment()

                            const date = now.format('YYYYMMDDHHmmss')

                            const objective_type =
                                this.infor_campaign.objective_type

                            this.infor_adgroups =
                                response_detail_adgroup.data.list.map(
                                    (adgroup) => {
                                        const ad = JSON.parse(
                                            JSON.stringify({
                                                ...this
                                                    .p__default_state_infor_ad,
                                                ad_name: `${this.$t(
                                                    'common.ad'
                                                )}_${date}`,
                                                extension: {
                                                    ...this
                                                        .p__default_state_infor_ad
                                                        .extension,
                                                    id: this.p__renderId()
                                                }
                                            })
                                        )

                                        this.changeInforAdByObjectiveType(
                                            ad,
                                            adgroup,
                                            objective_type
                                        )

                                        return {
                                            ...adgroup,
                                            extension: {
                                                ...this
                                                    .p__default_state_infor_adgroup
                                                    .extension,
                                                id: this.p__renderId(),
                                                infor_ads: [ad]
                                            }
                                        }
                                    }
                                )

                            this.step = 3
                        }
                    }
                } catch (error) {
                    this.step = 1
                    console.error(error)
                }

                this.m__loading = false
                return
            }

            this.step = 1
        },

        async fetchAdsAccountsByTiktokBusiness() {
            if (
                !this.m__selected_tiktok_business ||
                !this.m__selected_tiktok_business.id ||
                this.m__ads_accounts.length > 0
            ) {
                return
            }

            await this.m__fetchAdsAccountsByTiktokBusiness()

            if (!this.m__ads_accounts.length) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.tiktok_account_hasnt_ads_account')
                )
            }
        },
        async fetchAdsAccountsByBusinessCenter() {
            if (
                !this.m__selected_tiktok_business ||
                !this.m__selected_tiktok_business.id ||
                !this.m__selected_business_center ||
                !this.m__selected_business_center.bc_id ||
                this.m__ads_accounts.length > 0
            ) {
                return
            }

            await this.m__fetchAdsAccountsByBusinessCenter()

            if (!this.m__ads_accounts.length) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.tiktok_account_hasnt_ads_account')
                )
            }
        },

        changeInforAdgroupByObjectiveType(objective_type) {
            this.infor_campaign.campaign_type = ''
            this.infor_campaign.app_promotion_type = ''

            if (objective_type === 'REACH') {
                this.infor_adgroups[0].optimization_goal = 'REACH'
                this.infor_adgroups[0].billing_event = 'CPM'

                return
            }

            if (objective_type === 'TRAFFIC') {
                this.infor_adgroups[0].optimization_goal = 'CLICK'
                this.infor_adgroups[0].billing_event = 'CPC'
                this.infor_adgroups[0].promotion_type = 'WEBSITE'
                return
            }

            if (objective_type === 'VIDEO_VIEWS') {
                this.infor_adgroups[0].optimization_goal = 'ENGAGED_VIEW'
                this.infor_adgroups[0].billing_event = 'CPV'
                this.infor_adgroups[0].bid_display_mode = 'CPV'

                return
            }

            if (objective_type === 'LEAD_GENERATION') {
                this.infor_adgroups[0].optimization_goal = 'LEAD_GENERATION'
                this.infor_adgroups[0].billing_event = 'OCPM'
                this.infor_adgroups[0].promotion_type = 'LEAD_GENERATION'
                this.infor_adgroups[0].conversion_bid_price = 1

                return
            }

            if (objective_type === 'ENGAGEMENT') {
                this.infor_adgroups[0].optimization_goal = 'FOLLOWERS'
                this.infor_adgroups[0].billing_event = 'OCPM'
                this.infor_adgroups[0].conversion_bid_price = 1

                return
            }

            if (objective_type === 'APP_PROMOTION') {
                this.infor_adgroups[0].optimization_goal = 'CLICK'
                this.infor_adgroups[0].billing_event = 'CPC'
                this.infor_adgroups[0].operating_systems = ['ANDROID']
                this.infor_adgroups[0].promotion_type = 'APP_ANDROID'

                this.infor_campaign.campaign_type = 'REGULAR_CAMPAIGN'
                this.infor_campaign.app_promotion_type = 'APP_INSTALL'

                return
            }

            if (objective_type === 'WEB_CONVERSIONS') {
                this.infor_adgroups[0].optimization_goal = 'CONVERT'
                this.infor_adgroups[0].billing_event = 'OCPM'
                this.infor_adgroups[0].promotion_type = 'WEBSITE'
                this.infor_adgroups[0].conversion_bid_price = 1

                return
            }

            if (objective_type === 'CATALOG_SALES') {
                this.infor_adgroups[0].billing_event = 'CPM'
                this.infor_adgroups[0].shopping_ads_retargeting_type = 'OFF'

                return
            }

            if (objective_type === 'PRODUCT_SALES') {
                this.infor_adgroups[0].shopping_ads_type = 'LIVE'
                this.infor_adgroups[0].optimization_goal = 'CLICK'
                this.infor_adgroups[0].optimization_event = ''
                this.infor_adgroups[0].billing_event = 'CPC'
                this.infor_adgroups[0].promotion_type = 'LIVE_SHOPPING'
                this.infor_adgroups[0].store_authorized_bc_id = ''

                return
            }

            this.infor_adgroups[0].optimization_goal = 'CLICK'
            this.infor_adgroups[0].optimization_event = ''
        },

        changeInforAdByObjectiveType(ad, adgroup, objective_type) {
            if (objective_type === 'TRAFFIC') {
                ad.call_to_action = 'SHOP_NOW'

                return
            }

            if (objective_type === 'LEAD_GENERATION') {
                ad.call_to_action = 'SHOP_NOW'

                return
            }

            if (objective_type === 'APP_PROMOTION') {
                ad.call_to_action = 'DOWNLOAD_NOW'

                return
            }

            if (objective_type === 'WEB_CONVERSIONS') {
                ad.call_to_action = 'VIEW_NOW'

                return
            }

            if (objective_type === 'PRODUCT_SALES') {
                if (adgroup.shopping_ads_type === 'PRODUCT_SHOPPING_ADS') {
                    ad.product_specific_type = 'ALL'
                    ad.creative_type = 'PSA'
                }

                ad.extension.shopping_ads_type = adgroup.shopping_ads_type

                ad.extension.store_id = adgroup.store_id

                ad.extension.store_name = adgroup.extension.store_name

                ad.extension.store_authorized_bc_id =
                    adgroup.store_authorized_bc_id

                ad.extension.shopping_live_ad_setup = 'live_video'

                ad.extension.identity.identity_id = adgroup.identity_id

                ad.extension.identity.identity_type = adgroup.identity_type

                ad.extension.identity.identity_authorized_bc_id =
                    adgroup.identity_authorized_bc_id

                ad.extension.identity.display_name =
                    adgroup.extension.identity.display_name

                ad.extension.identity.image_uri =
                    adgroup.extension.identity.image_uri

                ad.call_to_action =
                    adgroup.shopping_ads_type === 'LIVE'
                        ? 'WATCH_LIVE'
                        : 'SHOP_NOW'

                return
            }
        },

        handleNextStepByExistingCampaign() {
            if (!this.selected_existing_campaign) {
                return
            }

            this.infor_campaign = {}

            this.step = 2
        },

        async fetchDetailAdsAccount() {
            if (!this.tiktok_account_id || !this.advertiser_id) {
                return
            }

            const response = await getAdvertiserAccountDetail(
                this.tiktok_account_id,
                [this.advertiser_id]
            )

            if (response.data.length) {
                const detail_ads_acc = response.data[0]
                this.currency = detail_ads_acc.currency
                this.timezone = detail_ads_acc.timezone

                this.changeBudgetByCurrency()
            }
        },

        changeBudgetByCurrency() {
            if (this.objective_type === 'PRODUCT_SALES') {
                this.min_budget_adgroup =
                    this.p__findMinimumDailyBudgetProductSalesAdgroup(
                        this.currency
                    )

                this.min_budget_campaign =
                    this.p__findMinimumDailyBudgetProductSalesCampaign(
                        this.currency
                    )
            } else {
                this.min_budget_adgroup = this.p__findMinimumDailyBudgetAdgroup(
                    this.currency
                )

                this.min_budget_campaign =
                    this.p__findMinimumDailyBudgetCampaign(this.currency)
            }
            this.infor_campaign.budget = this.min_budget_campaign
        },

        handleCreateAgain() {
            this.$confirm(
                this.$t('page.campaign.message_confirm_delete'),
                this.$t('common.warning'),
                {
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.step = 1
                this.active_tab = 'create_new'
                this.objective_type = 'PRODUCT_SALES'
                this.created_campaign_id = ''
                this.infor_campaign = default_state_infor_campaign
                this.infor_adgroups = []
                this.handleChangeObjectiveType()
            })
        },

        handleChangeAdsAccount(ads_account) {
            this.m__handleChangeAdsAccount(ads_account)
        },

        handleGoToStep2AfterCreatedSuccess() {
            this.step = 2

            const now = moment()
            const date = now.format('YYYYMMDDHHmmss')

            this.infor_adgroups = this.infor_adgroups.map((adgroup) => {
                return {
                    ...adgroup,
                    adgroup_name: `${this.$t('common.adgroup')}_${date}`
                }
            })

            // this.handleChangeObjectiveType()
        },

        async fetchCampaigns() {
            if (!this.advertiser_id) {
                return
            }

            this.m__loading = true

            try {
                const response = await getCampaigns(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    page: this.m__page_info.page,
                    page_size: this.m__page_info.page_size
                })

                this.m__data_for_pagination = [
                    ...this.m__data_for_pagination,
                    ...response.data.list
                ]
                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchCampaigns()
            }
        }
    }
}
</script>
